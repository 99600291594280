import React from 'react'

import { BrandListPage } from '../../components'

class Merken extends React.Component {
  render() {
    return <BrandListPage />
  }
}

export default Merken
